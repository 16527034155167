// -------------------------------------
// Navbar Toggler Active (animated hamburger icon)
// -------------------------------------
(function($) {
	$(function() {
		"use strict"

		var $toggler = $(".navbar-toggler");
		$toggler.on("click", function(e) {
			var $target = $(this).attr("data-target");

			if ($($target).hasClass("collapsing")) {
				// Do nothing
			} else {
				$(this).toggleClass("is-active");
			}
		});

	});
})(jQuery);