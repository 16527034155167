(function($) {
    $(document).ready(function(){
        "use strict";

        let div = document.getElementById("mooring04");

        console.log(div);





    });

})(jQuery);