// -------------------------------------
// Boat Section
// -------------------------------------
(function ($) {
	$(function () {
		var $section = $(".section_boat");

		if ($section.length) {
			var timer, timer2;

			// Change title + subtitle on hover
			$(".ship, .mooring").hover(
				function () {
					clearTimeout(timer2);

					var cardBackID = "#card-back-" + $(this).attr("id");

					// .1s delay before changing
					timer = setTimeout(function () {
						if ($(".card-front").hasClass("flip-out")) {
							// Do nothing
						} else {
							$(".card-front").removeClass("flip-in").addClass("flip-out");
						}
						$(".card-back.flip-in").removeClass("flip-in").addClass("flip-out");
						$(cardBackID).removeClass("flip-out").addClass("flip-in");
					}, 200);
				},
				function () {
					// clear delay
					clearTimeout(timer);

					timer2 = setTimeout(function () {
						$(".card-back.flip-in").removeClass("flip-in").addClass("flip-out");

						$(".card-front").removeClass("flip-out").addClass("flip-in");
					}, 350);
				}
			);

			// Open Modals on click
			$(".ship, .mooring").on("click", function () {
				var ID = $(this).attr("id");
				var $modal = $("#" + ID + "Modal");
				var $link = $("#link-" + ID);
				var $link_url = $link.data("url");

				if ($modal.length) {
					$modal.modal();
				} else if ($link_url.length) {
					window.location.href = $link_url;
				} else {
					var $mooringLink = $section.data("mooring-url");
					if ($mooringLink.length) {
						window.location.href = $mooringLink;
					}
				}
			});

			// Clicks on moorings link to mooring page
			// $(".mooring").on("click", function () {
			// 	var $mooringLink = $section.data("mooring-url");
			// 	if ($mooringLink.length) {
			// 		window.location.href = $mooringLink;
			// 	}
			// });
		}
	});
})(jQuery);
