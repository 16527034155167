// -------------------------------------
// Made by Dreamlab in console
// -------------------------------------
(function($) {
	$(function() {

		console.log("Made by Dreamlab (https://www.dreamlab.nl)");

	});

    $(document).ready(function(){

        /*! Fades in page on load */
        $('.title_logo').css('display', 'none').fadeIn(1750);


    });
})(jQuery);