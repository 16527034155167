// -------------------------------------
// Smoothly scroll to target
// -------------------------------------
(function($) {
	$(function() {

		// Scroll button
		var $a = $(".scroll-to-target");

		// Check if button exists
		if ($a.length) {

			$a.on("click", function(e) {
				// Prevent default behaviour
				e.preventDefault();

				// Defaults
				var $target = $(this).attr("href");
				var $duration = 500;
				var $navbar = $("#mainNavbar");
				var $adminbar = $("#wpadminbar");

				if ($(this).hasClass("scroll-to-target-top")) {
					var $top = true;
				}

				function getTargetOffset(target, top) {
					var $offset = $(target).offset().top;

					if (top === true) {
						$offset = $offset - 1;
						console.log($offset);
					} else {
						if ($navbar.hasClass("navbar_sticky")) {
							$offset = $offset - $navbar.outerHeight();
						}
					}

					if ($adminbar.length) {
						$offset = $offset - $adminbar.outerHeight();
					}

					return $offset;
				}

				// Get data attributes values if they exist
				if ($(this).data("target")) {
					$target = $(this).data("target");
				}
				if ($(this).data("duration")) {
					$duration = $(this).data("duration");
				}

				// Animate to top of target
				$("html, body").animate({
					scrollTop: getTargetOffset($target, $top)
				}, $duration, "linear");

			});

		}

	});
})(jQuery);