// -------------------------------------
// Rellax Parallax
// -------------------------------------
(function($) {
	$(function() {

		if ($(".rellax").length) {
			var rellax = new Rellax(".rellax", {
				speed: -2,
				center: false,
				wrapper: null,
				round: true,
				vertical: true,
				horizontal: false
			});
		}

	});
})(jQuery);