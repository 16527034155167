(function($) {
	$(function() {

		// NOTE: Breaks checkboxes

		// if (jQuery(".wpcf7-form").length) {
		// 	var astx_label_class = "custom-control-label",
		// 		astx_input_class = "custom-control-input",
		// 		astx_parent_class = "custom-control custom-checkbox custom-checkbox-inline";
		//
		// 	$(".wpcf7-form input[type=checkbox], .wpcf7-form input[type=radio]").each(function() {
		// 		var element = $(this),
		// 			parent = element.closest(".wpcf7-list-item");
		// 		element.addClass(astx_input_class);
		// 		parent.addClass(astx_parent_class);
		//
		// 		if (astx_label_class) {
		// 			parent.find(".wpcf7-list-item-label").addClass(astx_label_class);
		// 		}
		// 	});
		// }

	});
})(jQuery);