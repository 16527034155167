// -------------------------------------
// Bootstrap Alerts
// -------------------------------------
(function($) {
	$(function() {

		var alert = ".alert-dismissible";

		if ($(alert).length) {
			// Dismissable alerts / banners
			$(alert).alert();

			// Remember dismissed status via cookie (required alert to have an ID)
			$(alert).on("closed.bs.alert", function() {

				// Set cookie
				var $ID = $(this).attr("id");
				if ($ID.length) {
					eraseCookie($ID);
					setCookie($ID, 1, 365);
				}
			});
		}

	});
})(jQuery);