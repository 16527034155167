// -------------------------------------
// SlickSlider
// -------------------------------------
(function($) {
	var $slider = $(".slider");

	// Slider
	if ($slider.length) {
		$slider.on("init", function(event, slick) {
			$slider.find(".slide").matchHeight();
		});

		// Slick Slider
		$slider.slick({
			arrows: true,
			autoplay: true,
			autoplaySpeed: 10000,
			dots: false,
			draggable: true,
			focusOnSelect: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerPadding: 0,
			prevArrow: $(".prev"),
			nextArrow: $(".next"),
		});

	}

	var $boat = $(".boat_slider");

	// Slider
	if ($boat.length) {
		$boat.on("init", function(event, slick) {
			$boat.find(".slide").matchHeight();
		});

		// Slick Slider
		$boat.slick({
			arrows: true,
			autoplay: false,
			autoplaySpeed: 10000,
			dots: false,
			fade: true,
			cssEase: 'linear',
			draggable: false,
			focusOnSelect: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerPadding: 0,
			prevArrow: $(".boat_prev"),
			nextArrow: $(".boat_next"),
		});

	}

})(jQuery);