(function($) {
	// Homepage color changing logo
	if ($(".header_front_page").length) {

		window.addEventListener("scroll", function(event) {
			if (pageYOffset > (document.body.offsetHeight * 0.73)) {
				$((".logo-fixed-svg")).css({
					"stroke": "#87C1B6",
					"fill": "#87C1B6",
					"color": "#87C1B6"
				});
				$((".logo-fixed-svg .cls-2")).css("stroke", "#87C1B6");
				$((".logo-fixed-svg .cls-3")).css("stroke", "#87C1B6");
			} else if (pageYOffset > (document.body.offsetHeight * 0.55)) {
				$((".logo-fixed-svg")).css({
					"stroke": "#87C1B6",
					"fill": "#87C1B6",
					"color": "#87C1B6"
				});
				$((".logo-fixed-svg .cls-2")).css("stroke", "#87C1B6");
				$((".logo-fixed-svg .cls-3")).css("stroke", "#87C1B6");
			} else if (pageYOffset > (document.body.offsetHeight * 0.25)) {
				$((".logo-fixed-svg")).css({
					"stroke": "#5C9389",
					"fill": "#5C9389",
					"color": "#5C9389"
				});
				$((".logo-fixed-svg .cls-2")).css("stroke", "#5C9389");
				$((".logo-fixed-svg .cls-3")).css("stroke", "#5C9389");
			} else if (pageYOffset < (document.body.offsetHeight * 0.25)) {
				$((".logo-fixed-svg")).css({
					"stroke": "#39645B",
					"fill": "#39645B",
					"color": "#39645B"
				});
				$((".logo-fixed-svg .cls-2")).css("stroke", "#39645B");
				$((".logo-fixed-svg .cls-3")).css("stroke", "#39645B");
			}
		}, {
			capture: true,
			passive: true
		});
	}

	// if ($(".logo-fixed").length) {
	// 	window.addEventListener("scroll", function(event) {
	// 		if (pageYOffset > (document.body.offsetHeight * 0.73)) {
	// 			$((".logo-fixed")).css("bottom", "240px");
	// 			$((".logo-fixed")).css("top", "auto");
	// 		} else if (pageYOffset > (document.body.offsetHeight * 0.55)) {
	// 			$((".logo-fixed")).css("bottom", "auto");
	// 			$((".logo-fixed")).css("top", "310px");
	// 		} else if (pageYOffset > (document.body.offsetHeight * 0.25)) {
	// 			$((".logo-fixed")).css("bottom", "auto");
	// 			$((".logo-fixed")).css("top", "310px");
	// 		} else if (pageYOffset < (document.body.offsetHeight * 0.25)) {
	// 			$((".logo-fixed")).css("bottom", "auto");
	// 			$((".logo-fixed")).css("top", "310px");
	// 		}
	// 	}, {
	// 		capture: true,
	// 		passive: true
	// 	});
	// }

})(jQuery);