// -------------------------------------
// Drawer
// -------------------------------------
(function($) {
	$(window).on("load", function() {
		"use strict"

		var $navbar = $("#mainNavbar");
		var $adminbar = $("#wpadminbar");
		var $drawer = $(".drawer");
		var $toggler = $(".navbar-toggler");

		var speed = 250;

		function setDrawerPositionTop() {
			var scrollTop = $(window).scrollTop(),
				elementOffset = $navbar.offset().top + $navbar.outerHeight(),
				distance = (elementOffset - scrollTop);

			$drawer.css({
				"top": distance,
			});
		}

		// Set drawer position on toggler click
		$toggler.on("click resize", function() {
			setDrawerPositionTop();
		}).resize();


		if ($drawer.length) {
			// Loop through every drawer
			$.each($drawer, function(key, value) {
				var $this = $(this);

				var $id = $this.attr("id");
				var $thisToggler = $("[data-target=\"#" + $id + "\"]");

				// Show / Close when clicking toggle
				$thisToggler.on("click", function() {
					if ($this.hasClass("show")) {
						$drawer.addClass("transitioning").css("transform", "");
						$drawer.removeClass("show").addClass("hide");
						$toggler.removeClass("is-active");
						$thisToggler.removeClass("is-active");
					} else {
						$drawer.addClass("transitioning").css("transform", "");
						$drawer.removeClass("show").addClass("hide");
						$this.addClass("show").removeClass("hide");
						$toggler.removeClass("is-active");
						$thisToggler.addClass("is-active");
					}

					// Remove transitions
					setTimeout(function() {
						$drawer.removeClass("transitioning");
					}, speed);

				});

			});
		}

	});
})(jQuery);