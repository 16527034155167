// -------------------------------------
// Prevent scrolling back to header (landing area)
// -------------------------------------
(function($) {
	$(window).on("resize", function() {

		if ($(".header_front_page").length) {

			function noScrollingBackUp() {
				if ($(".header").hasClass("collapsed")) {
					window.removeEventListener("scroll", noScrollingBackUp);
				} else {
					var headerHeight = $(".header").outerHeight();

					if ($(window).scrollTop() > headerHeight) {
						$(".header").addClass("collapsed");
						$(window).trigger("resize");
					}
				}
			}

			// add listener to disable scrolling back up
			window.addEventListener("scroll", noScrollingBackUp, {
				capture: true,
				passive: true
			});


			$(".scroll-down-link").on("click", function() {
				setTimeout(function() {
					$(".header").addClass("collapsed");
				}, 800);
			});

		}


	}).resize();
})(jQuery);