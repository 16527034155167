// -------------------------------------
// Match Height
// -------------------------------------
(function($) {
	$(window).on("resize", function() {

		// Match Height
		$(".match-height").matchHeight();

	}).resize();
})(jQuery);