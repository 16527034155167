// -------------------------------------
// Fullscreen header (adjust height on resize)
// -------------------------------------
(function($) {
	$(window).on("resize", function() {

		var $header = $(".header_fullscreen");

		if ($header.length) {
			var $adminbar = $("#wpadminbar");
			// var $topbar = $("#topbar");
			// var $navbar = $("#mainNavbar");

			var $offset = 0;

			if ($adminbar.length) {
				$offset = $offset + $adminbar.outerHeight();
			}

			// if ($topbar.length) {
			// 	$offset = $offset + $topbar.outerHeight();
			// }

			// if ($navbar.length) {
			// 	$offset = $offset + $navbar.outerHeight();
			// }

			$header.height($(window).height() - $offset);
		}

	}).resize();
})(jQuery);